// Header.js
import React from 'react';
import Navigation from './Navigation'; // Import the Navigation component

function Header({ title, subtitle, header, buttons }) {
  return (
    <div className="header-container">
      <div className="header-title">
        
        <h1>{title}</h1>
        <div className="header-tagline">
            <p>{header}</p>
        </div>
        <div className="header-subtitle">
          <p>{subtitle}</p>
        </div>
      </div>
      <div className="header-center">
        {/* Include the Navigation component with buttons */}
      </div>
      <Navigation buttons={buttons} />
    </div>
  );
}

export default Header;
