import React, { lazy, Suspense } from 'react';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './Header';

import './App.css';

const Splash = lazy(() => import('./Splash'));
const About = lazy(() => import('./About'));
const Experience = lazy(() => import('./Experience'));
const Project = lazy(() => import('./Project'));
const Contact = lazy(() => import('./Contact'));

const MemoSplash = React.memo(Splash);
const MemoAbout = React.memo(About);
const MemoExperience = React.memo(Experience);
const MemoProject = React.memo(Project);
const MemoContact = React.memo(Contact);


function App() {


  return (
    <div>
      <Header title={"David Abramov"} subtitle={"Computational Media Ph.D."} header={"3D Graphics Software Engineer"} buttons={["About", "Experience", "Projects", "Contact"]} />
      
      <Suspense>
        <MemoSplash  />
      </Suspense>
      <Suspense>
        <MemoAbout />
      </Suspense>
      <Suspense>
        <MemoExperience />
      </Suspense>
      <Suspense>
        <MemoProject />
      </Suspense>
      <Suspense>
        <MemoContact />
      </Suspense>

    </div>
  );

}

export default App;
