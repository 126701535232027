import React, { useState, useEffect, useCallback } from 'react';

function Navigation({ buttons }) {
  const [activeSection, setActiveSection] = useState(null);

  const scrollToSection = (sectionId) => {
    const navigationContainerHeight = document.querySelector('.navigation-container').offsetHeight;
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      const offset = sectionElement.getBoundingClientRect().top - navigationContainerHeight;

      window.scrollTo({
        top: window.scrollY + offset,
        behavior: 'smooth',
      });

      // Set active section after scrolling
      setActiveSection(sectionId.toLowerCase());
    }
  };

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    let currentActiveSection = null;

    // Check if the scroll position is at the top of the page
    if (scrollPosition === 0) {
      currentActiveSection = 'home';
    } else {
      // Check for other sections
      buttons.forEach((section) => {
        const sectionElement = document.getElementById(section.toLowerCase());

        if (
          sectionElement &&
          scrollPosition >= sectionElement.offsetTop - window.innerHeight / 2 &&
          scrollPosition < sectionElement.offsetTop + sectionElement.offsetHeight - window.innerHeight / 2
        ) {
          currentActiveSection = section.toLowerCase();
        }
      });
    }

    setActiveSection(currentActiveSection);
  }, [buttons]);

  useEffect(() => {
    // Add smooth scroll polyfill if needed
    // SmoothScroll.polyfill();

    // Add scroll event listener on mount
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]); // Include handleScroll in the dependency array

  return (
    <div className="navigation-container">
      <div className="navigation-buttons">
        <button
          onClick={() => scrollToSection('home')}
          className={`navigation-button ${activeSection === 'home' ? 'active' : ''}`}
        >
          {"Home"}
        </button>
        {buttons.map((button, index) => (
          <button
            key={index}
            onClick={() => scrollToSection(button.toLowerCase())}
            className={`navigation-button ${activeSection === button.toLowerCase() ? 'active' : ''}`}
          >
            <img
                    src={`/icons/${button.toLowerCase()}.svg`}
                    alt={`${button} icon`}
                    className="section-icon"
                    fill="#6C63FF" 
                />
            {button}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Navigation;
